/*
    POINT D'ENTRÉE BRIKS
    Placer le chemin du fichier dans le fichier _config.yml de LibDoc
*/

/*
    CORE
    Fonctionnalités principales 
    Inclure briks/_core.scss pour obtenir les fonctions helpers et les mixins.
    Aucune déclaration CSS n'est générée à partir de ce fichier.
*/
@import 'briks/core';
/*
    DESIGN TOKENS
    Fichier SASS contenant les design tokens du projet
*/
@import 'briks/settings/tokens/ucar';
/*
    CONFIGURATION C-TXT GENERIQUE
    Requiert briks/_core.scss
    Imports du fichier de configuration c-txt.
    Les fichiers SASS composants générique sont présents dans le dépôt
    https://github.com/ita-design-system/jekyll-libdoc/tree/main/_sass/briks
*/
@import 'briks/txt_generic';
/*
    GÉNÉRATEUR DE COMPOSANTS
    Requiert  briks/_core.scss
    Génère toutes les déclarations CSS des composants
*/
@import 'briks/components';
